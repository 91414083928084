@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Cuprum';
  src: local('Cuprum'), url(./assets/fonts/Cuprum-Regular.ttf);
}
* {
  font-family: 'Cuprum';
  z-index: 1;
}
/*----------------------------------------------------------------*/

body {
  padding: 0 1rem 1rem 1rem;
  min-height: 100vh;
  background-image: url('/src/assets/imgs/background.png');
  z-index: 0;
}
@media screen and (max-width: 615px) {
  body {
    padding: 0 0.5rem 1rem 0.5rem;
  }
}

.w-ring {
  width: 8rem;
  left: -0.3rem;
  top: -4rem;
}

.dropMenu {
  position: absolute;
  width: 9rem;
  z-index: 20;
  top: 4.5rem;
  right: 1rem;
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.fade-out {
  animation-name: fade-out;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fade-in {
  animation-name: fade-in;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

/* Spinner */
.spinnerContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(105, 105, 105, 0.5);
  z-index: 50;
}
.spinner {
  width: 64px;
  height: 64px;
  border: 7px solid;
  border-color: #b91c1c transparent #facc15 transparent;
  border-radius: 75%;
  animation: spin 0.7s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(140deg);
  }
  40% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(300deg);
  }
  100% {
    transform: rotate(320deg);
  }
}
